import React, { useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

interface Props {
  accept: Array<string>
  details: string
  maxFiles: number
  disabled?: boolean
  setFiles: (files: Array<File>) => void
}

const StyledDropzone: React.FC<Props> = ({ accept, setFiles, details, maxFiles, disabled }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => setFiles(acceptedFiles), [])
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept, maxFiles, disabled })

  const fmt = Intl.NumberFormat('en', {
    style: 'unit',
    unit: 'kilobyte',
    maximumFractionDigits: 1,
  })

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.name}>
      <strong>{file.name}</strong> - {fmt.format(file.size / 1000)}
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <div className='container'>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>({details})</em>
      </div>
      <aside>
        {acceptedFileItems.length ? (
          <>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
          </>
        ) : null}
        {fileRejectionItems.length ? (
          <>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
          </>
        ) : null}
      </aside>
    </div>
  )
}

export default StyledDropzone
