import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import profile from './queries/profile'
import { profile as ProfileType } from './queries/types/profile'

import Terms from './pages/Terms'
import DashboardPage from './pages/DashboardPage'
import CreateProject from './pages/CreateProject'
import ImportProjects from './pages/ImportProjects'
import UpdateProjectPage from './pages/UpdateProjectPage'
import ProjectPage from './pages/ProjectPage'
import Profile from './pages/Profile'
import DraftsPage from './pages/DraftsPage'
import UpdateDraftPage from './pages/UpdateDraftPage'

import Container from './components/Container'
import NotFound from './pages/NotFound'

interface Props {
  sub: string
}

const Main: React.FC<Props> = ({ sub }) => {
  const { loading, error, data, refetch } = useQuery<ProfileType>(profile, {
    fetchPolicy: 'network-only',
  })

  if (error) {
    return <p>Oops something went wrong</p>
  }

  if (loading || !data) {
    return <p>Loading...</p>
  }

  if (!data.profile.firstName || !data.profile.lastName || !data.profile.jobTitle) {
    return <Profile sub={sub} profile={data.profile} refetch={refetch} />
  }

  return (
    <Container>
      <Switch>
        <Route exact path='/' component={DashboardPage} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/create-project' component={CreateProject} />
        <Route exact path='/import-projects' component={ImportProjects} />
        <Route exact path='/projects/:uuid' render={(props) => <ProjectPage {...props} />} />
        <Route exact path='/drafts' render={(props) => <DraftsPage {...props} />} />
        <Route exact path='/drafts/:uuid/edit' render={(props) => <UpdateDraftPage {...props} />} />
        <Route exact path='/projects/:uuid/edit' render={(props) => <UpdateProjectPage {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </Container>
  )
}

export default Main
