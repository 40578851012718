import { gql } from '@apollo/client'

export default gql`
  mutation createExportFile($query: String, $orderBy: ProjectOrder) {
    createExportFile(query: $query, orderBy: $orderBy) {
      url
      expiry
    }
  }
`
