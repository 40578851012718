import React from 'react'
import { Link } from 'react-router-dom'

import Container from '../components/Container'

const NotFound: React.FC = () => {
  return (
    <Container>
      <h1>Page not found</h1>
      <Link to={`/`}>Return home</Link>
    </Container>
  )
}

export default NotFound
