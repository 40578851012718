import React from 'react'

const Terms: React.FC = () => {
  return (
    <div style={{ padding: '2rem' }}>
      <h1 style={{ textAlign: 'center' }}>Net-Zero EC3 Database Platform Terms and Conditions</h1>
      <p style={{ padding: '2rem' }}>
        These are the Net-Zero EC3 Database Platform (the “Platform”) Terms and Conditions (“Terms and Conditions”).
      </p>
      <p>
        1.1 Net-Zero EC3 Database Platform The use of all pages on this Platform is subject to these Terms and
        Conditions. Access to certain parts of the Platform, or of other Digital Catapult Platforms or websites linked
        from here, may be restricted or subject to further conditions of use as indicated.
      </p>
      <p>
        1.2 Accuracy The material available on this Platform is designed to provide general information only. The
        content on the Platform is submitted by users, and is not verified by Digital Catapult. You are responsible for
        checking the accuracy of relevant facts and information given on the Platform before entering into any
        commitment based upon them. The information provided on this Platform does not constitute legal or other
        professional advice.
      </p>
      <p>
        1.3 Service We cannot guarantee that your use of this Platform will be free from error and/or interruption. We
        assume no responsibility or liability, so far as permitted by Law, for any damages including, but not limited
        to: indirect or consequential damages; damages for loss of opportunity or chance; any damages arising from use
        or loss of use, data, or profits, whether in action of contract, negligence or other tortious action, arising
        out of, or in connection with, any information on the Platform or any other Platforms or the use thereof.
      </p>
      <p>
        1.4 Computer viruses We have taken steps to ensure this Platform is free from viruses. However, we cannot always
        guarantee this. You are responsible for installing adequate and up-to-date virus-checking software on your
        computer and other devices. We take no responsibility or liability, so far as permitted by Law, for any viruses
        or any other computer code, files or programs designed to interrupt, restrict, destroy, limit the functionality
        of or compromise the integrity of any computer software or hardware; or telecommunications equipment or other
        material which may be transmitted with or as part of the Platform.
      </p>
      <p>
        1.5 Third party links Please note that the Digital Catapult cannot be held responsible for the contents of any
        pages referenced by an external link.
      </p>
      <p>
        1.6 Privacy Statement The privacy statement contained in these pages covers the services provided by the
        Net-Zero EC3 Database Platform. The Data Controller for this Platform is Digital Catapult. The purpose of this
        Privacy Statement is to inform users of this Platform, which is managed by Digital Catapult, about: What
        information is collected about them when they visit this Platform How this information is used How long this
        information will be retained for If this Privacy Statement changes in any way, we will place an updated version
        on these pages. Regularly reviewing this page ensures that you are always aware of what information we collect,
        how we use it and under what circumstances, if any, we will share it with other parties. You can find Digital
        Catapult’s Privacy Policy here.
      </p>
      <p>
        1.7 Information Confidentiality and Security Any information you provide will be held securely and in accordance
        with the data protection and privacy legislation in force from time to time in the UK including the retained EU
        law version of the General Data Protection Regulation ((EU) 2016/679) (UK GDPR) and the Data Protection Act 2018
        (DPA 2018). Your personal information will not normally be disclosed to third parties. In some circumstances
        however we may recommend you disclose your personal details to a third party so that they can provide a service
        you have requested, or fulfil a request for information. Any information about you that we pass to a third party
        will be held securely by that party, in accordance with the Data Protection Act 2018, and used only to provide
        the services or information you have requested. You have a right to request a copy of your personal details at
        any time to check the accuracy of the information held. If you wish to do this, please write to, or email:
        Digital Catapult 101 Euston Road London NW1 2RA Email: privacy@digicatapult.org.uk
      </p>
      <p>
        1.8 Information we collect from visitors We may collect several kinds of information from visitors and users of
        the Net-Zero EC3 Platform depending on the part of the Platform being visited or used: email addresses and/or
        Platform usage information.
      </p>
      <p>
        1.9 Feedback, comments and question If you wish to comment or submit an enquiry, email us
        at: luca.iadema@digicatapult.org.uk
      </p>
      <p>
        1.10 Update services sign up If you register to the Net-Zero EC3 Database Platform, we will use the information
        you give us to provide you with access to the Platform. We may occasionally contact users to help us evaluate
        and improve the Platform. If you sign up to our Platform, we will hold the information you have provided for as
        long as you require access to the Platform. If you inform us that you wish to unsubscribe from our Platform, we
        will use all reasonable endeavours to remove you from our Platform.
      </p>
      <p>
        1.11 Cookies By using the Platform you agree to our use of cookies as more specifically set out below. A cookie
        is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you
        agree. Cookies contain information that is transferred to your computer&#39;s hard drive. The cookies we use are
        limited to necessary cookies, which enable core functionality such as security, network management, and
        accessibility. You may disable these by changing your browser settings, but this may affect how the Platform
        functions.
      </p>
      <p>
        1.12 Log files Log files allow us to record visitors’ use of the Platform. The Digital Catapult puts together
        log file information from all our visitors, which we may use to make improvements to the layout of the Platform
        and to the information in it, based on the way that visitors and users move around it. Log files do not contain
        any personal information about you.
      </p>
      <p>
        1.13 Intellectual Property Any content you upload to the Platform will be considered non-confidential and
        non-proprietary. You retain all of your ownership rights in your content, but you agree to grant Digital
        Catapult and other users of the Platform a limited licence to use, store and copy that content and to distribute
        and make it available to third parties. Digital Catapult is the owner or the licensee of all intellectual
        property rights in our Platform, and in the material published on it. These works are protected by copyright and
        trademark laws and international treaties.   All rights in registered trademarks and logos are reserved.
      </p>
      <h1 style={{ textAlign: 'center' }}>Net-Zero EC3 Database Platform User Terms and Conditions</h1>
      <p>
        These are the Net-Zero EC3 Database Platform (the “Platform”) User Terms and Conditions. If you wish to view the
        Platform Terms and Conditions, please click here. You must read and agree to the below terms and conditions
        before registering to the Platform. Following registration, you will be a User of the Platform. These terms and
        conditions are subject to change from time to time, and you will be notified of any changes.
      </p>
      <p>
        1. Platform Management  Net-Zero EC3 is a platform delivered by Digital Catapult, which records the publicly
        funded projects that the Catapult network has undertaken, that are either currently active or were active over
        the past two years from March 2021 (the “Projects”). Each Project will reference a key contact and any questions
        on a specific Project must be directed at the key contact.
      </p>
      <p>
        2. Authorised Users You may only register as a User on the platform if you have an email address with the
        following email domain names:
      </p>
      <ul>
        <li>@digicatapult.org.uk</li>
        <li>@hvm.catapult.org.uk</li>
        <li>@csa.catapult.org.uk</li>
        <li>@cp.catapult.org.uk</li>
        <li>@es.catapult.org.uk</li>
        <li>@md.catapult.org.uk</li>
        <li>@ore.catapult.org.uk</li>
        <li>@sa.catapult.org.uk</li>
        <li>@namrc.co.uk</li>
        <li>@strath.ac.uk</li>
        <li>@nccuk.com</li>
        <li>@uk-cpi.com</li>
        <li>@sheffield.ac.uk</li>
        <li>@warwick.ac.uk</li>
        <li>@the-mtc.org</li>
        <li>@hvm.catapult.org.uk</li>
      </ul>
      This list may be updated from time to time by Digital Catapult.
      <p>
        3. Platform Use 3.1 If you are a User seeking to publish your data onto the Platform, you must: 3.1.1 use the
        Platform in a respectful manner and not compromise, or seek to compromise, the Platform. 3.1.2 ensure the
        integrity and quality of the data uploaded onto the platform. 3.1.3 ensure the data uploaded onto the Platform
        is limited to data in the public domain, which means that at the time the data is uploaded onto the Platform,
        the information can realistically be accessible to a member of the general public. 3.1.4 ensure no viruses or
        other malicious programs are transmitted onto or through the Platform. 3.1.5 ensure the personal data uploaded
        onto the Platform is limited to the contact email of the relevant Project key contact. 3.2 If you are a User
        seeking to access the information on the Platform, you: 3.2.1 must use the Platform in a respectful manner and
        not to compromise, or seek to compromise, the Platform. 3.2.2 must use the Project key contact contact
        information only for the purpose of contacting such person regarding the Project. 3.2.3 agree that whilst we ask
        Users publishing data to the Platform to ensure the integrity of their data, Digital Catapult is not responsible
        for the integrity, quality or availability of the data provided through the Platform, and the data is provided
        on an “as is” basis.
      </p>
      <p>
        4. Data Privacy 4.1 At Digital Catapult we believe that the correct treatment of personal data is integral to
        our successful operation and to maintaining trust of the persons we deal with. We fully align with the
        underlying principles of the data protection legislation and support and adhere to its provisions. 4.2 For the
        purposes of running the Platform, we will collect the following User personal information: 4.2.1 Email address
        4.3 This personal information will be used to: 4.3.1 Operate and maintain the Platform 4.3.2 Monitor the use of
        the Platform 4.3.3 Contact Users for feedback on their use of the Platform 4.3.4 Identify the key contact for
        the Projects 4.3.5 Provide Users with the Project key contact for other Users to contact such person regarding
        the Project. 4.4 The personal information collected will be retained until: 4.4.1 The User unregisters from the
        Platform; or 4.4.2 the information is no longer needed for the uses mentioned in 3 , being at most a period of
        five years. 4.5 Pursuant to an event detailed in 4.4 , your personal information will be deleted, however, any
        data you have published onto the Platform will be retained. 4.6
        <a href='https://www.digicatapult.org.uk/privacy-policy'>
          You can find Digital Catapult’s Privacy Policy here.
        </a>
      </p>
      <p>
        5. General  5.1 Any data you publish on the Platform will be considered non-confidential and non-proprietary.
        You retain all of your ownership rights in your data, but you agree to grant Digital Catapult and other users of
        the Platform a limited licence to use, store and copy that data and to distribute and make it available to third
        parties. 5.2 This Platform is intended for information only. Digital Catapult accept no liability for any
        consequences, whether direct or indirect, that may arise from Users’ registration to and use of the Platform, or
        Users’ reliance on the Platform or any statements we may have made about the Platform. 5.3 Digital Catapult
        endeavours to ensure the Platform is free from viruses and other malware. However, we cannot guarantee the
        Platform is or will be free from viruses and other malware. It is the responsibility of the Users to install
        adequate and up-to-date antivirus software on their computers and other devices.
      </p>
    </div>
  )
}

export default Terms
