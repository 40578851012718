import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import { Button, Card } from '@digicat/components'

import KPI from '../components/KPI'
import ExportButton from '../components/ExportButton'
import projects from '../queries/projects'
import { projects as projectsType } from '../queries/types/projects'
import { ProjectOrder, SortOrder } from '../types/graphql-global-types'

const DashboardPage: React.FC = ({ history }) => {
  // Query params
  const [params, setParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sort: withDefault(StringParam, ''),
    dir: withDefault(StringParam, ''),
  })

  // Internal state
  const { search: query, sort, dir } = params
  const orderBy = sort && dir ? { [sort]: dir } : undefined

  // Fetch state
  const { loading, error, data } = useQuery<projectsType>(projects, {
    fetchPolicy: 'network-only',
    variables: { query, orderBy },
  })

  const sortBy = (property: keyof ProjectOrder) => {
    if (sort === property) {
      // asc -> desc
      if (dir === SortOrder.asc) setParams({ ...params, dir: SortOrder.desc })
      // desc -> off
      else if (dir === SortOrder.desc) setParams({ ...params, dir: undefined, sort: undefined })
    } else {
      // off -> asc
      setParams({ ...params, dir: SortOrder.asc, sort: property })
    }
  }

  const FilterButton = ({ property }: { property: keyof ProjectOrder }) => (
    <Button
      style={{
        height: '30px',
        color: `${sort === property ? 'black' : '#808080'}`,
        padding: 0,
        marginRight: '10px',
      }}
      variant='text'
      onClick={() => sortBy(property)}
    >
      {property}
      {sort === property && dir === SortOrder.asc && <span>⬆</span>}
      {sort === property && dir === SortOrder.desc && <span>⬇ </span>}
    </Button>
  )

  const handleCardClick = ({ uuid }) => () => {
    history.push(`/projects/${uuid}`)
  }

  return (
    <div style={{ padding: '1rem' }}>
      <KPI projects={data?.projects} />
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Search
          autoFocus
          value={query}
          placeholder='Search for projects'
          onChange={(e) => setParams({ ...params, search: e.target.value ? e.target.value : undefined })}
          type='text'
          aria-label='Search for projects'
        />
        <Unicode style={{ position: 'absolute', fontSize: '30px', left: 0 }}>{'\u2315'}</Unicode>
      </div>
      <div style={{ marginLeft: '40px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'row wrap', margin: '20px 0 20px 0' }}>
          <h5 style={{ margin: 0 }}>SORT BY :</h5>
          <FilterButton property={'title'} />
          <FilterButton property={'field'} />
          <FilterButton property={'fundingProgramme'} />
          <FilterButton property={'valueGbp'} />
          <FilterButton property={'overview'} />
          <FilterButton property={'start'} />
          <FilterButton property={'end'} />
          <FilterButton property={'createdAt'} />
          <FilterButton property={'updatedAt'} />
          <FilterButton property={'partners'} />
          <FilterButton property={'technologies'} />
        </div>
        <ExportButton query={query} orderBy={orderBy} />
      </div>

      <section>
        {error && <p>{JSON.stringify(error)}</p>}
        {loading && <p>Loading...</p>}
        {data?.projects && (
          <CardContainer>
            {data?.projects.map((p) => (
              <Card
                key={p?.uuid}
                onClick={handleCardClick({ uuid: p.uuid })}
                title={p.title}
                name={(p.editors && p.editors[0].firstName) || p.contacts[0].name}
                description={p.description}
                date={new Date(p.start)}
              />
            ))}
          </CardContainer>
        )}
      </section>
    </div>
  )
}

const Unicode = styled.span`
  font-size: 18px;
  padding-left: 10px;
`

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 50px;
`

const Search = styled.input`
  border: 1px solid black;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  margin-left: 40px;
`

export default DashboardPage
