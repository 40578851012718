import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Input, TextArea } from '@digicat/components'

import Container from '../components/Container'
import MultiSelect from '../components/MultiSelect'
import StyledDropzone from '../components/StyledDropzone'
import Error from '../components/Error'
import Delete from '../components/icons/Delete'

import createProjectMutation from '../queries/createProject'
import { createProject as createProjectTypes } from '../queries/types/createProject'
import createDraftMutation from '../queries/createDraft'
import { createDraft as createDraftTypes } from '../queries/types/createDraft'
import catapults from '../queries/catapults'
import { catapults as catapultsType } from '../queries/types/catapults'
import { project_project_contacts } from '../queries/types/project'

const filesAccepted = ['image/*', '.pdf', '.docx', '.doc', '.xlsx', '.pptx', '.rtf', '.txt', '.md']
const uploadsDetails = 'Only PDF, images, and Microsoft Office files will be accepted'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required').max(500),
  overview: Yup.string().required('Required').max(10000),
  fundingProgramme: Yup.string().required('Required').max(500),
  valueGbp: Yup.number().required('Required').max(2147483647),
  partners: Yup.array().of(Yup.string().required('Required').max(1000)),
  technologies: Yup.array().of(Yup.string().optional().max(1000)),
  catapults: Yup.array().of(Yup.string()).min(1, 'Required').max(11),
  field: Yup.string().required('Required').max(100),
  start: Yup.date().required('Required'),
  end: Yup.date().required('Required'),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
      }),
    )
    .required(),
  description: Yup.string().required('Required').max(10000),
})

const [date, month, year] = new Date().toLocaleDateString('en-GB').split('/')
const today = `${year}-${month}-${date}`

interface FormValues {
  title: string
  overview: string
  fundingProgramme: string
  valueGbp: number | string
  field: string
  start: Date | string
  end: Date | string
  description: string
  partners: string
  technologies: string
  catapults: Array<string>
  contacts: Array<project_project_contacts>
  files: Array<File>
}

const CreateProject: React.FC = () => {
  const { data, error: catapultError } = useQuery<catapultsType>(catapults)
  const [createProject] = useMutation<createProjectTypes>(createProjectMutation)
  const [createDraft] = useMutation<createDraftTypes>(createDraftMutation)

  const [submitError, setSubmitError] = useState<Error>()
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true)
      setLoading(true)
      const { data } = await createProject({
        variables: {
          ...values,
          start: new Date(values.start),
          end: new Date(values.end),
          files: values.files.map((file) => {
            return { create: file }
          }),
        },
      })
      history.push(`/projects/${data.createProject.uuid}`)
    } catch (err) {
      setSubmitError(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleCreateDraft = async (values) => {
    setLoading(true)

    try {
      const { data } = await createDraft({
        variables: {
          ...values,
          start: new Date(values.start),
          end: new Date(values.end),
          valueGbp: values.valueGbp ? values.valueGbp : 0,
          files: values.files.map((file) => {
            return { create: file }
          }),
        },
      })
      setLoading(false)
      if (data) history.push(`/drafts`)
    } catch (err) {
      if (err.message === 'Invalid email') {
        setSubmitError(
          'Your email is not valid, please make sure you are using a valid email. Valid email domains are: digicatapult.org.uk, csa.catapult.org.uk, cp.catapult.org.uk, md.catapult.org.uk, sa.catapult.org.uk, es.catapult.org.uk, hvm.catapult.org.uk, ore.catapult.org.uk. If you are unsure why you are seeing this error please contact us.',
        )
      } else {
        setSubmitError("We couldn't create your draft, please try again or contact us.")
      }
    } finally {
      setLoading(false)
    }
  }

  const initialValues: FormValues = {
    title: '',
    overview: '',
    fundingProgramme: '',
    valueGbp: '',
    field: '',
    start: today,
    end: today,
    description: '',
    partners: [''],
    technologies: [''],
    catapults: [],
    contacts: [{ name: '', email: '' }],
    files: [],
  }

  return (
    <Container>
      <h2 style={{ marginBottom: '30px', fontFamily: 'Inter' }}>Create a new Project</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <InputWrapper>
              <Label htmlFor='title'>Title</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Title...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.title}
                  name='title'
                  id='title'
                />
                <ErrorMessage>{errors && touched && errors.title && touched.title && errors.title}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='overview'>Overview</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Overview...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.overview}
                  name='overview'
                  id='overview'
                />
                <ErrorMessage>
                  {errors && touched && errors.overview && touched.overview && errors.overview}
                </ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='funding-programme'>Funding Programme</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Funding Programme...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.fundingProgramme}
                  name='fundingProgramme'
                  id='funding-programme'
                />
                <ErrorMessage>{errors && touched && touched.fundingProgramme && errors.fundingProgramme}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='valueGbp'>Value GBP</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Value...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.valueGbp}
                  type='number'
                  name='valueGbp'
                  id='valueGbp'
                />
                <ErrorMessage>
                  {errors && touched && errors.valueGbp && touched.valueGbp && errors.valueGbp}
                </ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='start-date'>Start Date</Label>
              <div>
                <Field
                  as={Input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='date'
                  value={values.start.toString()}
                  name='start'
                  id='start-date'
                />
                <ErrorMessage>{errors && touched && errors.start && touched.start && errors.start}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='end-date'>End Date</Label>
              <div>
                <Field
                  as={Input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='date'
                  value={values.end.toString()}
                  name='end'
                  id='end-date'
                />
                <ErrorMessage>{errors && touched && errors.end && touched.end && errors.end}</ErrorMessage>
              </div>
            </InputWrapper>

            {/* Contacts */}
            <FieldArray
              name='contacts'
              render={(arrayHelpers) => (
                <>
                  <Label htmlFor='contact'>Contacts</Label>
                  {values.contacts.map((contact, index, arr) => (
                    <div style={{ display: 'flex', flexFlow: 'row wrap' }} key={`contact-${index}`}>
                      <Field
                        style={{ marginRight: '20px', marginBottom: '20px' }}
                        as={Input}
                        placeholder='Name...'
                        id='contacts.name'
                        name={`contacts[${index}].name`}
                      />
                      <Field as={Input} placeholder='Email...' id='contacts.email' name={`contacts[${index}].email`} />

                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, { name: '', email: '' })
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}

                  {errors && touched && errors.contacts && touched.contacts && errors.contacts && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push({ name: '', email: '' })}
                  >
                    Add more
                  </Button>
                </>
              )}
            />

            <InputWrapper>
              <Label htmlFor='field'>Field</Label>
              <div>
                <Field
                  as={Input}
                  value={values.field}
                  placeholder='Field...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  name='field'
                  id='field'
                />
                <ErrorMessage>{errors && touched && errors.field && touched.field && errors.field}</ErrorMessage>
              </div>
            </InputWrapper>

            {/* Partners */}
            <FieldArray
              name='partners'
              render={(arrayHelpers) => (
                <>
                  <Label htmlFor='partners'>Partners</Label>
                  {values.partners.map((partner, index, arr) => (
                    <div key={`partner-${index}`}>
                      <Field
                        as={Input}
                        placeholder='Partner...'
                        id={`partners[${index}]`}
                        name={`partners[${index}]`}
                      />
                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, '')
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}

                  {errors && touched && errors.partners && touched.partners && errors.partners && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push('')}
                  >
                    Add more
                  </Button>
                </>
              )}
            />

            {/* Technologies */}
            <FieldArray
              name='technologies'
              render={(arrayHelpers) => (
                <div>
                  <Label htmlFor='technologies'>Technologies</Label>
                  {values.technologies.map((technology, index, arr) => (
                    <div key={`partner-${index}`}>
                      <Field
                        as={Input}
                        placeholder='Technology...'
                        id={`technologies[${index}]`}
                        name={`technologies[${index}]`}
                      />
                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, '')
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}
                  {errors && touched && errors.technologies && touched.technologies && errors.technologies && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push('')}
                  >
                    Add more
                  </Button>
                </div>
              )}
            />

            <InputWrapper>
              <Label htmlFor='catapult-partners'>Catapult Partners</Label>
              <MultiSelect
                id='catapult-partners'
                name='catapults'
                value={values.catapults}
                options={data?.catapults || []}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              <ErrorMessage>
                {catapultError || (errors && touched && errors.catapults && touched.catapults && errors.catapults)}
              </ErrorMessage>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='description'>Description</Label>
              <TextArea
                placeholder='Description...'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name='description'
                id='description'
                rows={15}
                cols={10}
              />
              <ErrorMessage>
                {errors && touched && errors.description && touched.description && errors.description}
              </ErrorMessage>
            </InputWrapper>

            <Label htmlFor='files'>Files</Label>
            <InputWrapper>
              <StyledDropzone
                accept={filesAccepted}
                setFiles={(files: Array<File>) => setFieldValue('files', files)}
                details={uploadsDetails}
                maxFiles={10}
              />
            </InputWrapper>

            {submitError && <Error>{submitError}</Error>}
            <div style={{ margin: '50px 0', float: 'right' }}>
              <Button
                style={{ marginRight: '50px' }}
                variant='text'
                disabled={loading}
                onClick={() => handleCreateDraft(values)}
              >
                Save as draft
              </Button>
              <Button disabled={loading} type='submit'>
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`

const StyledLabel = styled.label`
  vertical-align: center;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  span {
    color: #e83f36;
  }
`

const Label = ({ children }) => (
  <StyledLabel>
    {children}
    <span>*</span>
  </StyledLabel>
)

const ErrorMessage = styled.p`
  color: #e83f36;
  display: block;
  height: 18px;
  margin: 2px 0;
  font-size: 12px;
`

export default CreateProject
