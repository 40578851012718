import { gql } from '@apollo/client'

export default gql`
  query profile {
    profile {
      email
      firstName
      lastName
      jobTitle
    }
  }
`
