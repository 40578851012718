import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import { Button, Input } from '@digicat/components'

import Container from '../components/Container'

import updateProfileMutation from '../queries/updateProfile'
import { profile_profile as ProfileType } from '../queries/types/profile'
import { updateProfile as UpdateProfileType } from '../queries/types/updateProfile'

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('first name is required'),
  lastName: Yup.string().required('last name is required'),
  jobTitle: Yup.string().required('job title is required'),
})

interface Props {
  sub: string
  profile: ProfileType
  // eslint-disable-next-line
  refetch: () => Promise<any>
}

export default function Profile({ sub, profile, refetch }: Props): JSX.Element {
  const [updateProfile] = useMutation<UpdateProfileType>(updateProfileMutation)

  const formik = useFormik({
    initialValues: profile,
    onSubmit: async (values, { setSubmitting }) => {
      console.log('Submitting...', values)
      setSubmitting(true)

      const response = await updateProfile({
        variables: values,
      })
      await refetch()
      setSubmitting(false)
      console.log('response', response)
    },
    validationSchema,
  })

  const { handleSubmit, errors, touched, handleChange, values, handleBlur } = formik

  return (
    <Container>
      <h1 style={{ marginBottom: '100px' }}>Update profile</h1>
      <form onSubmit={handleSubmit} noValidate={false}>
        <Input
          label='first name'
          disabled={sub.startsWith('google-oauth')}
          required
          placeholder='John'
          onChange={handleChange}
          onBlur={handleBlur}
          type='text'
          value={values.firstName || ''}
          name='firstName'
          id='firstName'
          error={errors && touched && errors.firstName && touched.firstName && errors.firstName}
        />

        <Input
          label='last name'
          disabled={sub.startsWith('google-oauth')}
          required
          placeholder='Smith'
          onChange={handleChange}
          onBlur={handleBlur}
          type='text'
          value={values.lastName || ''}
          name='lastName'
          id='lastName'
          error={errors && touched && errors.lastName && touched.lastName && errors.lastName}
        />

        <Input
          label='job title'
          required
          placeholder='Project manager'
          onChange={handleChange}
          onBlur={handleBlur}
          type='text'
          value={values.jobTitle || ''}
          name='jobTitle'
          id='jobTitle'
          error={errors && touched && errors.jobTitle && touched.jobTitle && errors.jobTitle}
        />

        <Button style={{ float: 'right', marginTop: '20px' }} type='submit'>
          Update profile
        </Button>
      </form>
    </Container>
  )
}
