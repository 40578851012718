import React from 'react'
import styled from 'styled-components'

const Error: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Wrapper>
    <h4>There is a problem</h4>
    <Definition>{children}</Definition>
  </Wrapper>
)

const Definition = styled.p`
  color: #d3351b;
`

const Wrapper = styled.div`
  padding: 20px;
  border: 2px solid #d3351b;
`

export default Error
