import React from 'react'

const CatapultLogo: React.FC = () => (
  <div>
    <svg width='150' height='40' viewBox='0 0 200 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M173.362 10.4832C169.763 10.4832 168.707 8.44912 168.707 5.24159C168.707 2.03405 169.763 0 173.362 0H195.345C198.944 0 200 2.07316 200 5.24159C200 8.44912 198.944 10.4832 195.345 10.4832H190.065V39.8986C190.065 43.6929 187.757 45.062 184.354 45.062C180.911 45.062 178.643 43.7711 178.643 39.8986V10.4832H173.362Z'
        fill='#161616'
      />
      <path
        d='M118.991 10.9524C118.991 7.51016 120.986 6.37579 123.959 6.37579C126.932 6.37579 128.927 7.54928 128.927 10.9133V28.8677C128.927 33.9528 130.765 36.6518 134.325 36.6518C137.924 36.6518 139.723 33.9528 139.723 28.8285V8.5663C139.723 5.12407 141.718 4.02881 144.73 4.02881C147.703 4.02881 149.698 5.2023 149.698 8.5663V28.9459C149.698 38.8423 145.356 45.3356 134.325 45.3356C123.333 45.3356 118.952 38.8814 118.952 28.985L118.991 10.9524Z'
        fill='#161616'
      />
      <path
        d='M158.499 44.7882C154.939 44.7882 153.57 42.9497 153.57 39.7813V6.72805C153.57 3.05112 155.683 1.91675 158.929 1.91675C162.176 1.91675 164.288 3.05112 164.288 6.72805V34.9309H171.251C174.576 34.9309 175.593 36.8867 175.593 39.8204C175.593 42.8324 174.576 44.71 171.251 44.71L158.499 44.7882Z'
        fill='#161616'
      />
      <path
        d='M90.5527 40.6813C90.5527 43.8889 92.5477 45.0232 95.3249 45.0232C98.3369 45.0232 100.058 43.8889 100.058 40.6813V35.1268H103.344C111.363 35.1268 116.135 30.472 116.135 22.7269C116.135 15.021 112.028 10.2097 101.584 10.2097H95.2858C91.9218 10.2097 90.5527 11.8526 90.5527 14.7863V40.6813ZM100.058 27.6165V17.7201H101.271C105.808 17.7201 106.63 20.067 106.63 22.6878C106.63 25.5824 105.769 27.6165 101.271 27.6165H100.058Z'
        fill='#161616'
      />
      <path
        d='M45.3724 24.3697C43.0254 24.3697 42.2822 22.8832 42.2822 20.8101C42.2822 18.6978 42.9863 17.2505 45.3724 17.2505L59.3761 17.2896C61.7621 17.2896 62.4662 18.8151 62.4662 20.8492C62.4662 22.8832 61.7621 24.4088 59.3761 24.4088H56.4032V41.5808C56.4032 44.2407 54.7603 45.0622 52.4133 45.0622C50.0664 45.0622 48.4235 44.2407 48.4235 41.5808V24.4088L45.3724 24.3697Z'
        fill='#161616'
      />
      <path
        d='M69.3904 38.4903H78.5436L79.3259 41.385C80.0691 44.3969 81.4773 45.0619 84.2154 45.0619C87.11 45.0619 89.1832 43.419 88.3618 40.3288C87.6186 37.5907 81.4382 18.6975 81.4382 18.6975C79.7171 13.8471 78.2306 13.0256 74.319 13.0256C70.3683 13.0256 68.921 13.9644 67.1607 18.7366C67.1607 18.7366 60.511 37.5516 59.6895 40.4071C58.7898 43.5755 60.7065 45.101 63.6011 45.101C66.4566 45.101 67.3563 44.3187 68.4124 41.3458L69.3904 38.4903ZM70.955 31.0191L74.0843 19.0104L76.8616 31.0191H70.955Z'
        fill='#161616'
      />
      <path
        d='M27.4982 39.6641H35.165L35.9473 42.0893C36.5341 44.3581 37.3946 45.0231 39.8199 45.0231C42.1277 45.0231 43.8488 43.7322 43.223 41.2288C42.6362 39.0774 37.3555 23.7047 37.3555 23.7047C36.0647 19.9886 34.6565 19.2063 31.5663 19.2063C28.437 19.2063 27.1462 19.9886 25.7771 23.7438C25.7771 23.7438 20.4573 39.0383 19.7923 41.307C19.0882 43.8105 20.692 45.0622 22.9998 45.0622C25.2295 45.0622 26.0509 44.319 26.8332 42.0502L27.4982 39.6641ZM29.1802 33.6402L31.4098 23.9003L33.9133 33.6402H29.1802Z'
        fill='#161616'
      />
      <path
        d='M10.092 38.6078C11.2655 38.6078 12.0869 38.2166 13.0257 37.5125C14.5904 36.3391 15.8421 36.4564 16.9765 37.9819C18.0717 39.4292 17.7979 40.9157 16.468 42.2456C15.0598 43.6147 12.5563 44.7882 9.5835 44.7882C3.87251 44.7882 0 40.0942 0 33.288C0 26.3253 4.30279 21.8269 10.0529 21.8269C13.1431 21.8269 15.2162 22.883 16.6244 24.1739C17.9153 25.3865 18.2282 26.7556 17.2503 28.242C16.3897 29.611 14.7469 30.2369 13.456 29.1808C12.7519 28.594 11.8522 27.7726 10.2876 27.7726C8.01884 27.7726 6.45419 29.924 6.45419 33.288C6.49331 36.4955 7.90149 38.6078 10.092 38.6078Z'
        fill='#161616'
      />
    </svg>
    <p style={{ padding: 0, margin: 0, fontWeight: 800, fontSize: '14px' }}>NET-ZERO DATABASE</p>
  </div>
)

export default CatapultLogo
