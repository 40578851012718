/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export interface ContactInput {
  name: string;
  email: string;
}

export interface ProjectOrder {
  title?: SortOrder | null;
  field?: SortOrder | null;
  fundingProgramme?: SortOrder | null;
  valueGbp?: SortOrder | null;
  overview?: SortOrder | null;
  start?: SortOrder | null;
  end?: SortOrder | null;
  createdAt?: SortOrder | null;
  updatedAt?: SortOrder | null;
  partners?: SortOrder | null;
  technologies?: SortOrder | null;
}

export interface UploadFile {
  connect?: UploadFileConnect | null;
  create?: any | null;
}

export interface UploadFileConnect {
  uuid: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
