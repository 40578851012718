import { gql } from '@apollo/client'

export default gql`
  mutation createProject(
    $title: String!
    $fundingProgramme: String!
    $valueGbp: Int!
    $overview: String!
    $field: String!
    $start: DateTime!
    $end: DateTime!
    $description: String!
    $partners: [String!]
    $technologies: [String!]
    $catapults: [String!]!
    $contacts: [ContactInput!]!
    $files: [UploadFile!]
  ) {
    createProject(
      title: $title
      fundingProgramme: $fundingProgramme
      valueGbp: $valueGbp
      overview: $overview
      field: $field
      start: $start
      end: $end
      description: $description
      partners: $partners
      technologies: $technologies
      catapults: $catapults
      contacts: $contacts
      files: $files
    ) {
      uuid
    }
  }
`
