import { gql } from '@apollo/client'

export default gql`
  mutation updateProfile($firstName: String!, $lastName: String!, $jobTitle: String!) {
    updateProfile(firstName: $firstName, lastName: $lastName, jobTitle: $jobTitle) {
      firstName
      lastName
      jobTitle
    }
  }
`
