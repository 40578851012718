import React, { useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Form, Formik, FieldArray, Field } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Input, TextArea } from '@digicat/components'

import Container from '../components/Container'
import MultiSelect from '../components/MultiSelect'
import StyledDropzone from '../components/StyledDropzone'
import Error from '../components/Error'
import Delete from '../components/icons/Delete'

import getProject from '../queries/project'
import catapults from '../queries/catapults'
import deleteFileQuery from '../queries/deleteFile'
import updateProjectMutation from '../queries/updateProject'
import { project as type, project_project_files } from '../queries/types/project'
import { catapults as catapultsType } from '../queries/types/catapults'
import { updateProject as updateProjectTypes } from '../queries/types/updateProject'
import { deleteFile } from '../queries/types/deleteFile'

const filesAccepted = ['image/*', '.pdf', '.docx', '.doc', '.xlsx', '.pptx', '.rtf', '.txt', '.md']
const uploadsDetails = 'Only PDF, images, and Microsoft Office files will be accepted'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required').max(500),
  overview: Yup.string().required('Required').max(10000),
  fundingProgramme: Yup.string().required('Required').max(500),
  valueGbp: Yup.number().required('Required').max(2147483647),
  partners: Yup.array().of(Yup.string().required('Required').max(1000)),
  technologies: Yup.array().of(Yup.string().optional().max(1000)),
  catapults: Yup.array().of(Yup.string()).min(1, 'Required').max(11),
  field: Yup.string().required('Required').max(100),
  start: Yup.date().required('Required'),
  end: Yup.date().required('Required'),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
      }),
    )
    .required(),
  description: Yup.string().required('Required').max(10000),
})

interface Contact {
  name: string
  email: string
}

interface FormValues {
  title: string
  overview: string
  fundingProgramme: string
  valueGbp: number | string
  field: string
  start: Date | string
  end: Date | string
  description: string
  partners: string
  technologies: string
  catapults: Array<string>
  contacts: Array<Contact>
  files: project_project_files[]
}

const UpdateProjectPage: React.FC<RouteComponentProps<{ uuid: string }>> = ({ match }) => {
  const [submitError, setSubmitError] = useState('')
  const { loading, error, data: projectData, refetch } = useQuery<type>(getProject, {
    variables: {
      uuid: match.params.uuid,
    },
  })
  const [deleteFileMutation] = useMutation<deleteFile>(deleteFileQuery)
  const [updateProject] = useMutation<updateProjectTypes>(updateProjectMutation)
  const history = useHistory()

  const { data: catapultsData, error: catapultError } = useQuery<catapultsType>(catapults)

  const deleteFile = async ({ uuid }: { uuid: string }) => {
    const confirmed = confirm('This will permanently delete the file for EVERYONE. Are you sure you want to do this?')
    if (confirmed) {
      if (await deleteFileMutation({ variables: { uuid } })) refetch()
    }
  }

  const createDate = (ISODate: string) => {
    const [date, month, year] = new Date(ISODate).toLocaleDateString('en-GB').split('/')
    return `${year}-${month}-${date}`
  }

  const { project } = projectData || {}

  const initialValues: FormValues = {
    title: project?.title || '',
    overview: project?.overview || '',
    fundingProgramme: project?.fundingProgramme || '',
    valueGbp: project?.valueGbp || '',
    field: project?.field || '',
    start: createDate(project?.start || new Date()),
    end: createDate(project?.end || new Date()),
    description: project?.description || '',
    partners: project?.partners || [''],
    technologies: project?.technologies || [''],
    catapults: project?.catapults.map(({ uuid }) => uuid) || [],
    contacts: project?.contacts.map(({ name, email }) => ({ name, email })) || [{ name: '', email: '' }],
    files: project?.files || [],
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      const { data } = await updateProject({
        variables: {
          uuid: project?.uuid,
          ...values,
          partners: values.partners.map((p) => p.trim()),
          technologies: values.technologies.map((t) => t.trim()),
          start: new Date(values.start),
          end: new Date(values.end),
          files: values.files.filter((file) => !file.uuid), // Only send new files
        },
        refetchQueries: [{ query: getProject, variables: { uuid: match.params.uuid } }],
      })
      if (data) history.push(`/projects/${data?.updateProject?.uuid}`)
    } catch (err) {
      setSubmitError(err.message)
    } finally {
      setSubmitting(false)
    }
  }

  if (error) return <p>{JSON.stringify(error)}</p>
  if (loading) return <p>Loading...</p>

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <InputWrapper>
              <Label htmlFor='title'>Title</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Title...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.title}
                  name='title'
                  id='title'
                />
                <ErrorMessage>{errors && touched && errors.title && touched.title && errors.title}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='overview'>Overview</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Overview...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.overview}
                  name='overview'
                  id='overview'
                />
                <ErrorMessage>
                  {errors && touched && errors.overview && touched.overview && errors.overview}
                </ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='funding-programme'>Funding Programme</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Funding Programme...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  value={values.fundingProgramme}
                  name='fundingProgramme'
                  id='funding-programme'
                />
                <ErrorMessage>{errors && touched && touched.fundingProgramme && errors.fundingProgramme}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='valueGbp'>Value GBP</Label>
              <div>
                <Field
                  as={Input}
                  placeholder='Value...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.valueGbp}
                  type='number'
                  name='valueGbp'
                  id='valueGbp'
                />
                <ErrorMessage>
                  {errors && touched && errors.valueGbp && touched.valueGbp && errors.valueGbp}
                </ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='start-date'>Start Date</Label>
              <div>
                <Field
                  as={Input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='date'
                  value={values.start.toString()}
                  name='start'
                  id='start-date'
                />
                <ErrorMessage>{errors && touched && errors.start && touched.start && errors.start}</ErrorMessage>
              </div>
            </InputWrapper>

            <InputWrapper>
              <Label htmlFor='end-date'>End Date</Label>
              <div>
                <Field
                  as={Input}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='date'
                  value={values.end.toString()}
                  name='end'
                  id='end-date'
                />
                <ErrorMessage>{errors && touched && errors.end && touched.end && errors.end}</ErrorMessage>
              </div>
            </InputWrapper>

            {/* Contacts */}
            <FieldArray
              name='contacts'
              render={(arrayHelpers) => (
                <>
                  <Label htmlFor='contact'>Contacts</Label>
                  {values.contacts.map((contact, index, arr) => (
                    <div style={{ display: 'flex', flexFlow: 'row wrap' }} key={`contact-${index}`}>
                      <Field
                        style={{ marginRight: '20px', marginBottom: '20px' }}
                        as={Input}
                        placeholder='Name...'
                        id='contacts.name'
                        name={`contacts[${index}].name`}
                      />
                      <Field as={Input} placeholder='Email...' id='contacts.email' name={`contacts[${index}].email`} />

                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, { name: '', email: '' })
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}

                  {errors && touched && errors.contacts && touched.contacts && errors.contacts && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push({ name: '', email: '' })}
                  >
                    Add more
                  </Button>
                </>
              )}
            />

            <InputWrapper>
              <Label htmlFor='field'>Field</Label>
              <div>
                <Field
                  as={Input}
                  value={values.field}
                  placeholder='Field...'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='text'
                  name='field'
                  id='field'
                />
                <ErrorMessage>{errors && touched && errors.field && touched.field && errors.field}</ErrorMessage>
              </div>
            </InputWrapper>

            {/* Partners */}
            <FieldArray
              name='partners'
              render={(arrayHelpers) => (
                <>
                  <Label htmlFor='partners'>Partners</Label>
                  {values.partners.map((partner, index, arr) => (
                    <div key={`partner-${index}`}>
                      <Field
                        as={Input}
                        placeholder='Partner...'
                        id={`partners[${index}]`}
                        name={`partners[${index}]`}
                      />
                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, '')
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}

                  {errors && touched && errors.partners && touched.partners && errors.partners && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push('')}
                  >
                    Add more
                  </Button>
                </>
              )}
            />

            {/* Technologies */}
            <FieldArray
              name='technologies'
              render={(arrayHelpers) => (
                <div>
                  <Label htmlFor='technologies'>Technologies</Label>
                  {values.technologies.map((technology, index, arr) => (
                    <div key={`partner-${index}`}>
                      <Field
                        as={Input}
                        placeholder='Technology...'
                        id={`technologies[${index}]`}
                        name={`technologies[${index}]`}
                      />
                      <Button
                        type='button'
                        variant='text'
                        onClick={() => {
                          if (arr.length === 1) {
                            arrayHelpers.replace(index, '')
                          } else {
                            arrayHelpers.remove(index)
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  ))}
                  {errors && touched && errors.technologies && touched.technologies && errors.technologies && (
                    <ErrorMessage>Required</ErrorMessage>
                  )}
                  <Button
                    style={{ marginBottom: '20px' }}
                    type='button'
                    variant='text'
                    onClick={() => arrayHelpers.push('')}
                  >
                    Add more
                  </Button>
                </div>
              )}
            />

            {catapultsData && (
              <InputWrapper>
                <Label htmlFor='catapult-partners'>Catapult Partners</Label>
                <MultiSelect
                  id='catapult-partners'
                  name='catapults'
                  value={values.catapults}
                  options={catapultsData?.catapults || []}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                <ErrorMessage>
                  {catapultError || (errors && touched && errors.catapults && touched.catapults && errors.catapults)}
                </ErrorMessage>
              </InputWrapper>
            )}

            <InputWrapper>
              <Label htmlFor='description'>Description</Label>
              <TextArea
                placeholder='Description...'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                name='description'
                id='description'
                rows={15}
                cols={10}
              />
              <ErrorMessage>
                {errors && touched && errors.description && touched.description && errors.description}
              </ErrorMessage>
            </InputWrapper>

            {project?.isEditor && (
              <>
                <Label htmlFor='files'>Files</Label>
                <ul>
                  {values.files
                    .filter((file) => !!file.uuid)
                    .map((file) => (
                      <li key={file.uuid}>
                        <a href={file.download.url} target='_blank' rel='noopener noreferrer'>
                          {decodeURIComponent(file.uuid.split('/')[1])}
                        </a>
                        <Button
                          variant='text'
                          type='button'
                          style={{ marginLeft: '1rem' }}
                          onClick={() => deleteFile({ uuid: file.uuid })}
                        >
                          Delete
                        </Button>
                      </li>
                    ))}
                </ul>
              </>
            )}

            <Label htmlFor='files'>Files</Label>
            <InputWrapper>
              <StyledDropzone
                accept={filesAccepted}
                setFiles={(files: Array<File>) => setFieldValue('files', files)}
                details={uploadsDetails}
                maxFiles={10}
              />
            </InputWrapper>

            {submitError && <Error>{submitError}</Error>}
            <Button style={{ float: 'right', marginTop: '20px', marginBottom: '50px' }} type='submit'>
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`
const StyledLabel = styled.label`
  vertical-align: center;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  span {
    color: #e83f36;
  }
`

const Label = ({ children }) => (
  <StyledLabel>
    {children}
    <span>*</span>
  </StyledLabel>
)

const ErrorMessage = styled.p`
  color: #e83f36;
  display: block;
  height: 18px;
  margin: 2px 0;
  font-size: 12px;
`

export default UpdateProjectPage
