import React from 'react'

import { Button } from '@digicat/components/'

import Container from '../components/Container'

const WelcomePage: React.FC<{ loginWithRedirect: () => void }> = ({ loginWithRedirect }) => {
  return (
    <Container>
      <h1>Cross Catapult Net Zero Database</h1>
      <p style={{ padding: '2rem' }}>
        This database stores details of the net zero activities that the Catapult network has undertaken. All projects
        recorded here are publicly funded and further details are available through the funding body. Each project
        contains a key contact, any questions on that specific project should be directed to this individual. This
        database is intended for information only and access to the database is limited to those employed by the
        Catapult network.
      </p>
      <h4>Before you login</h4>
      <p>Please make sure you are logging in with one of the following email domains:</p>
      <ul>
        <li>digicatapult.org.uk</li>
        <li>csa.catapult.org.uk</li>
        <li>cp.catapult.org.uk</li>
        <li>md.catapult.org.uk</li>
        <li>sa.catapult.org.uk</li>
        <li>es.catapult.org.uk</li>
        <li>hvm.catapult.org.uk</li>
        <li>ore.catapult.org.uk</li>
      </ul>

      <Button style={{ margin: '1rem' }} onClick={loginWithRedirect}>
        Login
      </Button>
    </Container>
  )
}

export default WelcomePage
