import { ParseError, ParseMeta } from 'papaparse'
import { GraphQLError } from 'graphql/error/GraphQLError'
import styled from 'styled-components'

type ImportResult = {
  success: boolean
  errors: Readonly<GraphQLError[]> | Error[] | undefined
}

type ImportProject = {
  title: string
  overview: string
  fundingProgramme: string
  valueGbp: number
  start: string
  end: string
  contactName: string
  contactEmail: string
  field: string
  partners: string
  technologies: string
  description: string
}

interface Props {
  data: ImportProject[]
  errors: ParseError[]
  meta: ParseMeta
  importResults: ImportResult[]
}

function getResult(project: ImportProject, index: number, importResults: ImportResult[]): string {
  if (!importResults.length) {
    return '⏺' // Ready
  }
  if (!importResults[index]) {
    return '🚀' // Uploading
  }
  if (importResults[index].success) {
    return '✅' // Success
  }

  return `❌ ${importResults[index].errors[0].message}`
}

export default function PreviewTable({ data, errors, importResults }: Props): JSX.Element {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <TR>
            <TH>Title</TH>
            <TH>Overview</TH>
            <TH>Funding programme</TH>
            <TH>Value GBP</TH>
            <TH>Start Date</TH>
            <TH>End Date</TH>
            <TH>Contact name</TH>
            <TH>Contact email</TH>
            <TH>Field</TH>
            <TH>Partners</TH>
            <TH>Technologies</TH>
            <TH>Description</TH>
            <TH>Result</TH>
          </TR>
        </thead>
        <tbody>
          {data.map((p, i) => (
            <TR key={p.title}>
              <TD>{p.title}</TD>
              <TD>{p.overview}</TD>
              <TD>{p.fundingProgramme}</TD>
              <TD>{Number(p.valueGbp)}</TD>
              <TD>{p.start}</TD>
              <TD>{p.end}</TD>
              <TD>{p.contactName}</TD>
              <TD>{p.contactEmail}</TD>
              <TD>{p.field}</TD>
              <TD>{p.partners}</TD>
              <TD>{p.technologies}</TD>
              <TD>{p.description}</TD>
              <TD style={{ color: 'red', textAlign: 'center' }}>{getResult(p, i, importResults)}</TD>
            </TR>
          ))}
        </tbody>
        {errors.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={13}>{errors.length} lines were not properly formatted and were ignored</td>
            </tr>
          </tfoot>
        )}
      </Table>
    </div>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TR = styled.tr`
  border: 1px solid black;
`

const TH = styled.th`
  border: 1px solid black;
  padding: 1rem;
`

const TD = styled.td`
  border: 1px solid black;
  padding: 1rem;
`
