import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import Delete from '../components/icons/Delete'
import { catapults_catapults } from '../queries/types/catapults'

const SelectedList = styled.div`
  position: relative;
  padding: 5px;
  list-style-type: none;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  margin-bottom: 0px;
  cursor: text;
`

const SelectedItem = styled.li`
  display: inline-block;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  margin: 2px 10px;
`

const UnselectedListWrapper = styled.div`
  position: relative;
  width: 50%;
`
const UnselectedList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid black;
  margin: 0;
  background: white;
`

const UnselectedItem = styled.li`
  font-size: 14px;
  padding: 10px 10px;
  &:hover {
    background-color: #c3c3c3;
  }
`

interface Props {
  name: string
  value: Array<string>
  options: catapults_catapults[]
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => Promise<void>
  handleBlur: {
    (e: React.FocusEvent<unknown>): void
    <T = unknown>(fieldOrEvent: T): T extends string ? (e: unknown) => void : void
  }
}

const MultiSelect: React.FC<Props> = ({ name, setFieldValue, options, value, handleBlur }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const dropdownElement = useRef(null)

  const handleClickOutside = (event: React.MouseEvent) => {
    if (dropdownElement.current && !dropdownElement.current.contains(event.target)) {
      setDropdownVisible(false)
      handleBlur({ target: { name } })
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownElement])

  const selected = options.filter((option) => value.includes(option.uuid))
  const unselected = options.filter((option) => !value.includes(option.uuid))

  const generateFieldValue = (newValue: string, values: Array<string>) => {
    if (values.includes(newValue)) {
      return values.filter((val) => val !== newValue)
    }
    return [...values, newValue]
  }

  const handleClick = (newValue: string) => {
    setFieldValue(name, generateFieldValue(newValue, value))
  }

  return (
    <div style={{ width: '80%' }}>
      <SelectedList onClick={() => setDropdownVisible(!dropdownVisible)}>
        {selected.map((selection) => (
          <SelectedItem key={selection.uuid}>
            <span style={{ padding: '0 5px' }}>{selection.name}</span>
            <span
              onClick={(e) => {
                e.stopPropagation()
                handleClick(selection.uuid)
              }}
              style={{ padding: '6px' }}
            >
              <Delete />
            </span>
          </SelectedItem>
        ))}
      </SelectedList>
      {dropdownVisible && unselected.length > 0 && (
        <UnselectedListWrapper ref={dropdownElement}>
          <UnselectedList>
            {unselected.map((ignored) => (
              <UnselectedItem onClick={() => handleClick(ignored.uuid)} key={ignored.uuid}>
                {ignored.name}
              </UnselectedItem>
            ))}
          </UnselectedList>
        </UnselectedListWrapper>
      )}
    </div>
  )
}

export default MultiSelect
