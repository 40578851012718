import React from 'react'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Card } from '@digicat/components'

import drafts from '../queries/drafts'
import { drafts as type } from '../queries/types/drafts'

const DraftsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, error, data } = useQuery<type>(drafts, { fetchPolicy: 'network-only' })

  const handleCardClick = ({ uuid }) => () => {
    history.push(`/drafts/${uuid}/edit`)
  }

  if (error) return <p>{JSON.stringify(error)}</p>
  if (loading) return <p>Loading...</p>
  return (
    <div>
      <section>
        {error && <p>{JSON.stringify(error)}</p>}
        {loading && <p>Loading...</p>}
        {data?.drafts && data?.drafts.length < 1 && (
          <p>
            <i>
              You have no drafts, you can click <b>Create Project</b> and save projects as Drafts before creating them.
            </i>
          </p>
        )}
        {data?.drafts && (
          <CardContainer>
            {data?.drafts.map((draft) => (
              <Card
                key={draft.uuid}
                title={draft.title}
                name={draft.editors[0].firstName}
                date={new Date(draft.start)}
                description={draft.description}
                onClick={handleCardClick({ uuid: draft.uuid })}
              />
            ))}
          </CardContainer>
        )}
      </section>
    </div>
  )
}

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding-top: 50px;
`

export default DraftsPage
