import React from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import { Button } from '@digicat/components'

import Main from './Main'
import Logo from './components/Logo'
import Terms from './pages/Terms'
import WelcomePage from './pages/WelcomePage'

const Header = styled.header`
  padding: 30px 60px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LinkButton = styled(Link)`
  font-weight: 600;
  color: black;
  padding: 11px 30px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
`

const Footer = styled.div`
  border-top: 1px solid #f0f0f0;
`

const FooterHeader = styled.div`
  padding: 2rem;
  text-align: center;
`

const Feedback = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2;
  color: #18c1ee;
  text-decoration: none;
`

const App: React.FC = () => {
  const { error, isAuthenticated, isLoading, loginWithRedirect, logout, user } = useAuth0()

  return (
    <div>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <Header>
              <Link style={{ textDecoration: 'none', color: 'black' }} to='/'>
                <Logo />
              </Link>
              <div style={{ display: 'flex' }}>
                {isAuthenticated && <LinkButton to='/create-project'>Create Project</LinkButton>}
                {isAuthenticated && <LinkButton to='/import-projects'>Import Projects</LinkButton>}
                {isAuthenticated && (
                  <>
                    <LinkButton to='/drafts'>drafts</LinkButton>
                    <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
                  </>
                )}
              </div>
            </Header>

            {/* Body */}
            <div style={{ flexGrow: 1 }}>
              {error && <div>Authentication error: {error}</div>}
              {isLoading && <div>Loading...</div>}
              {isAuthenticated ? (
                <Main sub={user?.sub} />
              ) : (
                <Switch>
                  <Route exact path='/terms' component={Terms} />
                  <Route
                    path='/'
                    render={(props) => <WelcomePage loginWithRedirect={loginWithRedirect} {...props} />}
                  />
                </Switch>
              )}
            </div>

            {/* Footer */}
            <Footer>
              <FooterHeader>
                <div>Questions, comments, or issues?</div>
                <Feedback>
                  <a
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=NetZero Support`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Provide feedback
                  </a>
                </Feedback>
                <Feedback>
                  <Link to={'/terms'}>Terms &amp; Conditions</Link>
                </Feedback>
                <Feedback>
                  <a href={`https://www.digicatapult.org.uk/privacy-policy`} target='_blank' rel='noopener noreferrer'>
                    Privacy Policy
                  </a>
                </Feedback>
              </FooterHeader>
            </Footer>
          </div>
        </QueryParamProvider>
      </Router>
    </div>
  )
}

export default App
