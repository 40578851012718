import React from 'react'
import styled from 'styled-components'

import { projects_projects } from '../queries/types/projects'

const KPI: React.FC<{ projects?: projects_projects[] }> = ({ projects }) => {
  if (projects) {
    let totalGbp = 0
    let numPartners = 0
    const uniqFunders = new Set()
    projects.forEach((p) => {
      totalGbp += p.valueGbp
      numPartners += p.partners.length
      uniqFunders.add(p.fundingProgramme)
    })

    const fmt = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'narrowSymbol',
    })

    return (
      <Container>
        <Title>CROSS CATAPULT NET-ZERO REPORTS SUMMARY</Title>
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stat value={String(projects.length)} label={`Project${projects.length > 1 ? 's' : ''}`} />
          <Stat value={fmt.format(totalGbp)} label={'Total projects value'} />
          <Stat value={String(numPartners)} label={`Partner${numPartners > 1 ? 's' : ''}`} />
          <Stat value={String(uniqFunders.size)} label={`Funding bod${uniqFunders.size > 1 ? 'ies' : 'y'}`} />
        </div>
      </Container>
    )
  } else return null
}

const Stat = ({ value, label }: { value: string; label: string }) => (
  <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
    <BigNumber>{value}</BigNumber>
    <BigLabel>{label}</BigLabel>
  </div>
)

const BigNumber = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 144px;
  line-height: 120px;
  display: flex;
  align-items: center;
`

const BigLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
`

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
`

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`

export default KPI
