import React from 'react'
import { RouteComponentProps, Link, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { Button } from '@digicat/components'

import project from '../queries/project'
import query from '../queries/deleteProject'
import {
  project as type,
  project_project_files as fileType,
  project_project_contacts as ContactType,
} from '../queries/types/project'
import { deleteProject as deleteType } from '../queries/types/deleteProject'
import { getDuration } from '../utils'

import LeftArrow from '../components/icons/LeftArrow'
import Edit from '../components/icons/Edit'

const ProjectPage: React.FC<RouteComponentProps<{ uuid: string }>> = ({ match }) => {
  const history = useHistory()
  const [deleteMutation] = useMutation<deleteType>(query, { variables: { uuid: match.params.uuid } })
  const { loading, error, data } = useQuery<type>(project, {
    variables: {
      uuid: match.params.uuid,
    },
  })

  if (error) return <p>{JSON.stringify(error)}</p>
  if (loading) return <p>Loading...</p>

  const deleteProject = async () => {
    const name = prompt('Enter the name of this project to delete it')
    if (name === data?.project.title) {
      if (await deleteMutation()) history.replace('/')
    }
  }

  return data?.project ? (
    <Container>
      <Left>
        <SvgLink to={'/'} aria-label='back'>
          <LeftArrow />
        </SvgLink>
      </Left>
      <Middle>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <h1 style={{ marginTop: 0, paddingRight: '1rem' }}>
            <Link to={`/?search=${data?.project.title}`}>{data?.project.title}</Link>
          </h1>
          {data.project.isEditor && (
            <SvgLink to={`/projects/${match.params.uuid}/edit`} aria-label='edit project'>
              <Edit />
            </SvgLink>
          )}
        </div>
        <List label={'Authors'} searchable={false} values={data?.project.contacts.map((c) => c.name)} />
        <p style={{ paddingTop: '1rem' }}>{data?.project.overview}</p>
        <p style={{ paddingTop: '1rem' }}>{data?.project.description}</p>
        <Downloads files={data?.project.files} />
      </Middle>
      <Right>
        <div>Funding programme</div>
        <h1 style={{ marginTop: 0 }}>{data?.project.fundingProgramme}</h1>

        <div>Total value</div>
        <h1 style={{ marginTop: 0 }}>{fmt.format(data?.project.valueGbp)}</h1>

        <div style={{ display: 'flex' }}>
          <div>
            <div>Start date</div>
            <h1 style={{ marginTop: 0 }}>{new Date(data?.project.start).toLocaleDateString()}</h1>
          </div>
          <div style={{ paddingLeft: '2rem' }}>
            <div>Duration</div>
            <h1 style={{ marginTop: 0 }}>
              {getDuration({ start: new Date(data?.project.start), end: new Date(data?.project.end) })}
            </h1>
          </div>
        </div>
        <List label={'Fields'} searchable values={[data?.project.field]} />
        <List label={'Partners'} searchable values={data?.project.partners} />
        <List label={'Technologies'} searchable values={data?.project.technologies || []} />
        <Contacts label='Contacts' contacts={data?.project.contacts} />
        <List label={'Catapult Partners'} searchable values={data?.project.catapults.map((c) => c.name)} />
        {data.project.isEditor && (
          <Button variant='outlined' onClick={() => deleteProject()}>
            delete project
          </Button>
        )}
      </Right>
    </Container>
  ) : null
}

const List: React.FC<{ label: string; values: (string | null)[]; searchable?: boolean }> = ({
  label,
  values,
  searchable = false,
}) => (
  <>
    <strong>{label}</strong>
    <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
      {values.map((v) => {
        if (searchable) {
          return (
            <Link key={v} to={`/?search=${encodeURIComponent(String(v))}`}>
              <p style={{ padding: '0.5rem', marginTop: 0 }}>{v}</p>
            </Link>
          )
        } else {
          return (
            <p key={v} style={{ padding: '0.5rem', marginTop: 0 }}>
              {v}
            </p>
          )
        }
      })}
    </div>
  </>
)

const Downloads: React.FC<{ files: Array<fileType> | null }> = ({ files }) => {
  return files ? (
    <>
      <strong style={{ paddingTop: '1rem' }}>Downloads</strong>
      <ul>
        {files.map((file) => (
          <li key={file.uuid}>
            <a href={file.download.url} target='_blank' rel='noopener noreferrer'>
              {decodeURIComponent(file.uuid.split('/')[1])}
            </a>
          </li>
        ))}
      </ul>
    </>
  ) : null
}

const Contacts: React.FC<{ label: string; contacts: ContactType[] }> = ({ label, contacts }) => (
  <>
    <strong>{label}</strong>
    <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
      {contacts.map(({ name, email }) => (
        <p key={email} style={{ padding: '0.5rem', marginTop: 0 }}>
          <a href={`mailto:${email}?subject=NetZero`}>{name}</a>
        </p>
      ))}
    </div>
  </>
)

const SvgLink = styled(Link)`
  text-decoration: none;
`

const Left = styled.div`
  flex-grow: 1;
  flex-basis: 10%;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  text-align: end;
`

const Middle = styled.div`
  flex-grow: 2;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Right = styled.div`
  flex-grow: 3;
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
`

const fmt = Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'GBP',
  currencyDisplay: 'symbol',
})

export default ProjectPage
