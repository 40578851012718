import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/link-error'
import { Button } from '@digicat/components'

import './index.css'
import App from './App'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV || 'development',
    release: process.env.REACT_APP_COMMIT_SHA || 'unknown',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => Sentry.captureMessage(message))
    }
    if (networkError) {
      Sentry.captureException(networkError)
    }
  }

  // Optionally, set response.errors to null to ignore the captured errors
  // at the component level. Omit this if you still want component-specific handling
  //response.errors = null
})

function AuthApp() {
  const { getAccessTokenSilently, isAuthenticated, isLoading, error, logout } = useAuth0()

  if (error) {
    return (
      <div>
        <p>Oops... {error.message}</p>
        <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
      </div>
    )
  }

  if (isLoading) {
    return <p>Loading...</p>
  }

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_URI,
  })

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: isAuthenticated ? `Bearer ${await getAccessTokenSilently()}` : '',
      },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat((errorLink as unknown) as ApolloLink).concat((httpLink as unknown) as ApolloLink),
  })

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
      redirectUri={window.location.origin}
      audience={`https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`}
      scope='openid profile email'
    >
      <AuthApp />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
