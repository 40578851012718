import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Papa, { ParseError, ParseResult } from 'papaparse'
import { useQuery, useMutation } from '@apollo/client'
import { GraphQLError } from 'graphql/error/GraphQLError'
import { Button } from '@digicat/components'

import Container from '../components/Container'
import PreviewTable from '../components/PreviewTable'

import createDraftMutation from '../queries/createDraft'
import StyledDropzone from '../components/StyledDropzone'
import { camelize } from '../utils'
import { createDraft as createDraftTypes } from '../queries/types/createDraft'
import template from '../queries/template'
import { template as templateType } from '../queries/types/template'

const Field = styled.div`
  display: flex;
  min-height: 50px;
  align-items: top;
  margin-bottom: 20px;
`
const InputWrapper = styled.div`
  width: 80%;
`

const Label = styled.label`
  width: 20%;
  text-transform: uppercase;
  font-weight: 600;
`

type ImportResult = {
  success: boolean
  errors: Readonly<GraphQLError[]> | Error[] | undefined
}

type ImportProject = {
  title: string
  overview: string
  fundingProgramme: string
  valueGbp: number
  start: string
  end: string
  contactName: string
  contactEmail: string
  field: string
  partners: string
  technologies: string
  description: string
}

const CreateProject: React.FC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [parseError, setParseError] = useState<ParseError>()
  const [parseResults, setParseResults] = useState<ParseResult<ImportProject> | null>(null)
  const [importResults, setImportResults] = useState<Array<ImportResult>>([])
  const [files, setFiles] = useState<File[]>()
  const [createDraft] = useMutation<createDraftTypes>(createDraftMutation)
  const { data } = useQuery<templateType>(template)
  const history = useHistory()

  useEffect(() => {
    if (files && files.length) {
      Papa.parse<ImportProject>(files[0], {
        header: true,
        comments: true,
        skipEmptyLines: true,
        transformHeader: (header) => camelize(header.toLowerCase().trim()),
        transform: (str) => str.trim(),
        complete: (results) => setParseResults(results),
        error: (error) => setParseError(error),
      })
    }
    setSubmitted(false)
    setImportResults([])
  }, [files])

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    createProjects()
  }

  async function createProjects() {
    if (parseResults == null) {
      return
    }

    setSubmitting(true)
    setImportResults([])

    for (const values of parseResults.data) {
      try {
        const { data, errors } = await createDraft({
          variables: {
            ...values,
            start: values.start && new Date(values.start),
            end: values.end && new Date(values.end),
            valueGbp: Number(values.valueGbp),
            partners: values.partners ? values.partners.split(',').map((partner) => partner.trim()) : undefined,
            technologies: values.technologies ? values.technologies?.split(',').map((t) => t.trim()) : undefined,
            contacts:
              values.contactName && values.contactName
                ? { name: values.contactName, email: values.contactEmail }
                : undefined,
          },
        })

        if (data && data.createDraft) {
          const importResult: ImportResult = { success: !!data.createDraft.uuid, errors }
          setImportResults((cur) => [...cur, importResult])
        }
      } catch (err) {
        setImportResults((cur) => [...cur, { success: false, errors: [err] }])
      }
    }

    setSubmitting(false)
    setSubmitted(true)
  }

  return (
    <Container>
      <h1 style={{ marginBottom: '100px' }}>Import a batch of projects</h1>
      <form onSubmit={handleSubmit} noValidate={true}>
        {data && (
          <Field>
            <Label htmlFor='example'>Example</Label>
            <InputWrapper>
              <a href={data?.template.url}>Download</a>
            </InputWrapper>
          </Field>
        )}
        <Field>
          <Label htmlFor='files'>Projects</Label>
          <InputWrapper>
            <StyledDropzone
              accept={['text/csv', '.csv']}
              setFiles={setFiles}
              details='Only csv format is accepted'
              maxFiles={1}
              disabled={submitting}
            />
          </InputWrapper>
        </Field>

        {parseError && <p>Oops something went wrong while parsing the file: {parseError.message}</p>}

        {submitting && <p>Submitting...</p>}

        {parseResults && <PreviewTable {...parseResults} importResults={importResults} />}

        {submitted && (
          <Button style={{ marginTop: '20px' }} onClick={() => history.push('/drafts')}>
            View drafts
          </Button>
        )}
        <Button
          style={{ float: 'right', marginTop: '20px' }}
          type='submit'
          disabled={!!parseError || submitting || submitted || !parseResults}
        >
          Import
        </Button>
      </form>
    </Container>
  )
}

export default CreateProject
