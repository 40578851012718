import React from 'react'

const LeftArrow: React.FC = () => (
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.04004 20.4841H35.028V17.1721H5.04004' fill='#333333' />
    <path d='M0.540039 18.8279L12.312 30.5999L14.688 28.2599L2.91604 16.4519' fill='#333333' />
    <path d='M2.91604 21.1679L14.688 9.39591L12.312 7.05591L0.540039 18.8279' fill='#333333' />
  </svg>
)

export default LeftArrow
