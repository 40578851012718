import { gql } from '@apollo/client'

export default gql`
  query draft($uuid: String!) {
    draft(uuid: $uuid) {
      uuid
      title
      fundingProgramme
      valueGbp
      overview
      field
      start
      end
      description
      partners
      technologies
      catapults {
        uuid
        name
      }
      contacts {
        uuid
        name
        email
      }
      editors {
        email
        firstName
        lastName
      }
      files {
        uuid
        filename
        mimetype
        encoding
        download {
          url
          expiry
        }
      }
    }
  }
`
