import React from 'react'

const Edit: React.FC = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.6618 15.4441V18.2381H3.67382V3.89406H13.6618V7.06206L15.6198 5.08206V1.91406H1.71582V20.1961H15.6198V13.4861L13.6618 15.4441Z'
      fill='#333333'
      fillOpacity='0.3'
    />
    <path
      d='M11.6161 15.0259L8.93213 15.7299L9.68013 13.0899L19.3821 3.43188L21.3401 5.36788L11.6161 15.0259Z'
      fill='#333333'
      fillOpacity='0.3'
    />
  </svg>
)

export default Edit
