import React, { useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { Button } from '@digicat/components'

import createExportFileQuery from '../queries/createExportFile'
import { projectsVariables as ProjectsVariables } from '../queries/types/projects'
import { project_project_files_download as DownloadLink } from '../queries/types/project'

function getButtonState({ error, loading, file }: ExportStatus) {
  if (error) {
    console.error(error)
    return { text: 'Export failed - Retry' }
  }
  if (loading) {
    return { disabled: true, text: 'Exporting...' }
  }
  if (file) {
    console.log(file)
    return { disabled: false, text: 'Success!' }
  }
  return { text: 'Export results as CSV' }
}

interface ExportStatus {
  loading: boolean
  error?: ApolloError | Error | undefined
  file?: DownloadLink | null | undefined
}

const ExportButton: React.FC<ProjectsVariables> = ({ query, orderBy }) => {
  const [exportStatus, setExportStatus] = useState<ExportStatus>({ loading: false })
  const [createExportFile] = useMutation<DownloadLink>(createExportFileQuery)

  async function handleClick() {
    setExportStatus({ loading: true })
    try {
      const res = await createExportFile({ variables: { query, orderBy } })
      setExportStatus({ loading: false, file: res.data.createExportFile })
    } catch (error) {
      setExportStatus({ loading: false, error })
    }
  }

  if (exportStatus.file?.url) {
    return <a href={exportStatus.file.url}>Download</a>
  }

  const { disabled, text } = getButtonState(exportStatus)

  return (
    <Button variant='outlined' disabled={disabled} onClick={handleClick}>
      {text}
    </Button>
  )
}

export default ExportButton
