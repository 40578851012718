import { gql } from '@apollo/client'

export default gql`
  query template {
    template {
      url
      expiry
    }
  }
`
