import { gql } from '@apollo/client'

export default gql`
  query catapults($query: String) {
    catapults(query: $query) {
      uuid
      name
    }
  }
`
