export const getDuration = ({ start, end }: { start: Date; end: Date }): string => {
  const diffTime = Math.abs(end.getTime() - start.getTime())
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  const diffWeeks = Math.floor(diffDays / 7)
  const diffMonths = Math.floor(diffDays / 30.417)
  const diffYears = Math.floor(diffDays / 365)

  const nf = new Intl.NumberFormat()
  if (diffYears) return `${nf.format(diffYears)}Y`
  else if (diffMonths) return `${nf.format(diffMonths)}M`
  else if (diffWeeks) return `${nf.format(diffWeeks)}W`
  else return `${diffDays}D`
}

export function camelize(str: string): string {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export default {
  getDuration,
}
