import { gql } from '@apollo/client'

export default gql`
  query projects($query: String, $orderBy: ProjectOrder) {
    projects(query: $query, orderBy: $orderBy) {
      uuid
      title
      fundingProgramme
      valueGbp
      overview
      field
      start
      end
      description
      partners
      technologies
      catapults {
        uuid
        name
      }
      contacts {
        uuid
        name
        email
      }
      files {
        uuid
        filename
        mimetype
        encoding
      }
    }
  }
`
